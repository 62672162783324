<template>
  <fieldset>
    <DocFields
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields-bottom>
        <FilterOptions
          v-slot="{ filtredOptions }"
          :modelValue="vectors"
          :fieldName="'name'"
        >
          <UniversalSelectField
            class="form-control"
            :modelValue="data.settlement"
            @update:modelValue="update('settlement', $event, v$.settlement)"
            data-qa="settlement"
            :keyProp="'externalId'"
            :displayProp="'name'"
            :can-find="true"
            :hasError="v$.settlement.$error"
            @filter="fetchWithDelay($event)"
            :options="filtredOptions.value"
          >
            <label class="label label--required">Поселение</label>
            <template #validation>
            <ValidationMsg :errors="v$.settlement.$errors"></ValidationMsg>
          </template>
          </UniversalSelectField>
        </FilterOptions>
      </template>
    </DocFields>
  </fieldset>
</template>

<script>
import DocGPLoc from "../../../models/documents/docGPLoc";
import DocFields from "./DocFields";
import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";
import { useVectorSettlement } from "@/hooks/vector";
import FilterOptions from "../../basic/renderless/FilterOptions.vue";
import ValidationMsg from "../../basic/form/select/ValidationMsg";
import UniversalSelectField from "@/components/basic/form/select/UniversalSelectField.vue";

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocGPLoc(),
    },
    documentTypeId: {
      type: Number,
      required: true,
    },
    documentRegNum: {
      type: String,
      required: false,
    },
  },
  components: {UniversalSelectField, DocFields, FilterOptions, ValidationMsg },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { vectors, fetchWithDelay } = useVectorSettlement();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      settlement: {
        required: helpers.withMessage("Заполните поле", required),
      },
      files: helpers.withMessage("TPR Warning", (value) => {
        if (!value.files) return true;
        return value.files.some(
          (x) => x !== null && x["isTerritorialPlanningRegulation"] == true
        );
      }),
    };

    const v$ = useValidator(data, rules);

    return { data, update, vectors, fetchWithDelay, v$ };
  },
};
</script>